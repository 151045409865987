// app/javascript/controllers/log_action_controller.js

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="log-action"
export default class extends Controller {
  connect() {
    // Attach click event listener
    document.addEventListener("click", this.logAction)
  }

  disconnect() {
    // Remove click event listener when the controller disconnects
    document.removeEventListener("click", this.logAction)
  }

  logAction(event) {
    const target = event.target;
    if (target.matches("a") || target.matches("button")) {
      fetch("/log_action", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": document.querySelector("meta[name=csrf-token]").content
        },
        body: JSON.stringify({ action_description: target.innerText })
      });
    }
  }
}
