// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import './add_jquery'
import * as bootstrap from "bootstrap"
import "./channels"

window.bootstrap = bootstrap;

// Initialize popovers (or tooltips, if used)
let popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
popoverTriggerList.map(function (popoverTriggerEl) {
    return new bootstrap.Popover(popoverTriggerEl);
});

// Initialize Dismissible Alerts on Turbo Frame Loads
document.addEventListener('turbo:load', function () {
    var alertList = [].slice.call(document.querySelectorAll('.alert-dismissible'))
    alertList.map(function (alertEl) {
        return new bootstrap.Alert(alertEl);
    });
});



// Function to display a flash message dynamically
function displayFlash(message, type) {
    const flashContainer = document.getElementById('flash-messages');
    if (flashContainer) {
        const flashMessage = document.createElement('div');
        flashMessage.classList.add('alert', `alert-${type}`, 'alert-dismissible', 'fade', 'show');
        flashMessage.setAttribute('role', 'alert');
        flashMessage.innerHTML = `
            ${message}
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        `;
        flashContainer.appendChild(flashMessage);

        // Smooth fade-out after 5 seconds
        setTimeout(() => {
            flashMessage.classList.add('fade-out'); // Adds fade-out transition class
        }, 5000);

        // Remove from DOM after fade-out transition ends
        flashMessage.addEventListener('transitionend', () => {
            flashMessage.remove();
        });
    }
}

// Listen for Turbo Frame Stream Events to Intercept Flash Messages
document.addEventListener('turbo:before-stream-render', function(event) {
    // Check for flash messages in response HTML
    const responseHTML = event.detail.renderOptions.html;
    const flashMessages = responseHTML.match(/<div[^>]*class="alert"[^>]*>([\s\S]*?)<\/div>/);

    if (flashMessages) {
        flashMessages.forEach((flashHTML) => {
            const $flash = $(flashHTML);
            const message = $flash.text().trim();
            const type = $flash.hasClass('alert-success') ? 'success' : 'danger';
            displayFlash(message, type);
        });

        // Remove flash messages from Turbo's rendering to prevent duplicates
        event.detail.renderOptions.html = responseHTML.replace(/<div[^>]*class="alert"[^>]*>[\s\S]*?<\/div>/, '');
    }
});

document.addEventListener("DOMContentLoaded", () => {
    const alerts = document.querySelectorAll('.alert-dismissible[data-bs-autohide="true"]');
    alerts.forEach(alert => {
        const bsAlert = new bootstrap.Alert(alert);
        setTimeout(() => alert.classList.add('fade-out'), alert.getAttribute('data-bs-delay') || 1000);
        alert.addEventListener('transitionend', () => alert.remove());
    });




});
